<template>
  <div class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      :title="handleReminderTime(activeItem.reminderTime)"
      :mask-closable="false"
      :width="564"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
      :getContainer="() => container"
      class="issModal"
    >
      <div>
        <div class="mb-24">
          {{ activeItem.content }}
        </div>

        <!--  人脉圈  -->
        <div v-if="activeItem.personnelType === 1" class="typeOne">
          <div class="avatarClass mbOne">
            <div class="name nameC">
              <component
                :is="icons[activeItem.connections.icon]"
                class="iconClass"
                @click="
                  handleGoToContact(
                    activeItem.connections,
                    activeItem.personnelType
                  )
                "
              >
              </component>
              <div
                class="textMl ml-8"
                @click="
                  handleGoToContact(
                    activeItem.connections,
                    activeItem.personnelType
                  )
                "
              >
                {{ activeItem.connections.connectionsName }}
              </div>
            </div>
          </div>

          <!--          <div class="avatarClass">-->
          <div
            v-for="item in activeItem.personnelVoList"
            :key="item.id"
            class="name nameC"
          >
            <a-avatar
              v-if="item.avatar"
              :src="setAvatar(item.avatar)"
              class="headerUrl"
            />
            <div v-else class="headerText headerTextC">{{ item.name[0] }}</div>
            <div class="nameText ml-8">{{ item.name }}</div>
          </div>
          <!--          </div>-->
        </div>

        <!--    联系人的    -->
        <div v-else>
          <div class="avatarClass mb">
            <div
              v-for="item in activeItem.personnelVoList"
              :key="item.id"
              class="name nameMb"
            >
              <a-avatar
                v-if="item.avatar"
                :src="setAvatar(item.avatar)"
                class="headerUrl"
              />
              <div v-else class="headerText">{{ item.name[0] }}</div>
              <div class="nameText">{{ item.name }}</div>
            </div>
          </div>
        </div>

        <div
          v-for="item in activeItem.subItemsList"
          :key="item.id"
          class="subContent"
        >
          <span class="sub-content" :class="{ showLine: item.completed }">{{
            item.subContent
          }}</span>
          <span class="sub-time">{{
            handleReminderTime(item.reminderTime)
          }}</span>
          <div class="ribbon">
            <a-tooltip placement="top" v-if="item.completed">
              <template #title>
                <span>撤回完成</span>
              </template>
              <div
                class="dropDown"
                @click="handleCheck(activeItem, item, false)"
              >
                <RedoOutlined class="editImg" />
              </div>
            </a-tooltip>
            <a-tooltip placement="top" v-else>
              <template #title>
                <span>完成待办</span>
              </template>
              <div class="gouGou" @click="handleCheck(activeItem, item, true)">
                <CheckOutlined class="gou" />
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <a-button @click="handleEdit">编辑</a-button>
        <a-button type="primary" @click="handleOk">全部完成</a-button>
      </template>
    </a-modal>
  </div>
  <edit-page
    v-model:visible="editPage"
    :init="activeItem"
    @fnOk="handleEditPage"
  />
  <!--  </div>-->
</template>
<script>
import { reactive, toRefs, watch, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import editPage from '@/views/today/homePage/components/editPage';
import todayApi from '@/api/today';
import { message, Avatar, Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import { setAvatar } from '@/utils';

export default {
  components: {
    AAvatar: Avatar,
    editPage,
    RedoOutlined: icons.RedoOutlined,
    CheckOutlined: icons.CheckOutlined,
    ATooltip: Tooltip,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const router = useRouter();
    const container = ref();
    const store = useStore();
    const userId = store.state.account.user.id;
    console.log('userId', userId);
    const form = reactive({});

    const state = reactive({
      editPage: false,
      activeItem: {},
      id: '',
      tiem: '',
    });

    watch(
      () => props.init,
      init => {
        // console.log('init123', init);
        state.id = init.id;
        todayApi.getMatter('', init.id).then(res => {
          if (res) {
            state.activeItem = res;
            // console.log('res555', state.activeItem);
          }
        });
      },
      { deep: true }
    );

    onMounted(() => {});

    const handleCancel = () => {
      // todo 打开待办编辑弹窗
      // Object.assign(state.activeItem, parmas);
      context.emit('fnOk', false);
    };

    const handleEdit = () => {
      // todo 打开待办编辑弹窗
      state.editPage = true;
      // Object.assign(state.activeItem, parmas);
      context.emit('fnOk', false);
    };

    return {
      container,
      ...toRefs(state),
      form,
      icons,
      setAvatar,
      handleCancel,
      handleEdit,
      handleOk: () => {
        // todo 调取待办完成接口
        // 调取主待办完成接口
        todayApi
          .markTodoCompleted('', {
            userId: store.state.account.user.id,
            ...state.activeItem,
            id: state.id,
            completed: true,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              context.emit('fnOk', false);
            }
          });
      },
      handleEditPage(value) {
        state.editPage = value;
      },
      handleReminderTime(item) {
        console.log('item66', item);
        if (item) {
          // 创建一个日期对象
          // let dateObject = new Date(item);
          // // 提取月份、日期、小时和分钟
          // let month = dateObject.getMonth() + 1; // 月份从0开始，所以需要加1
          // let day = dateObject.getDate();
          // let hours = dateObject.getHours();
          // let minutes = dateObject.getMinutes();
          //
          // // 格式化输出
          // let tiem = `${month}月${day}日 ${hours}:${minutes}`;
          // return tiem;

          let date = new Date(item);
          // let year = date.getFullYear();
          let month = (date.getMonth() + 1).toString().padStart(2, '0');
          let day = date.getDate().toString().padStart(2, '0');
          let hours = date.getHours().toString().padStart(2, '0');
          let minutes = date.getMinutes().toString().padStart(2, '0');
          // let seconds = date.getSeconds().toString().padStart(2, '0');
          // :${seconds}
          let formattedDate = `${month}月${day}日 ${hours}:${minutes}`;
          return formattedDate;
        } else {
          return '';
        }
      },
      handleCheck(item, listItem, completeState) {
        // console.log('handleCheck', listItem, listItem.completed);
        // 调取子待办完成接口
        todayApi
          .markTodoSubItemsCompleted('', {
            userId: store.state.account.user.id,
            todoId: item.id,
            id: listItem.id,
            completed: completeState,
          })
          .then(res => {
            if (res) {
              message.success('操作成功');
              // 刷新列表
              // initList(state.isCurrent, state.size);
              todayApi
                .queryTodoSubItem('', {
                  id: listItem.id,
                })
                .then(res => {
                  listItem.subContent = res.subContent;
                  listItem.completed = res.completed;
                });
            }
          });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.tabsRight {
  height: 550px;
}
.tabsRight /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
.content {
  //max-height: calc(100vh - 356px);
  //max-height: calc(100vh - 321px);
  //overflow-y: auto;
  height: 475px;
  overflow: auto;
  margin-top: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 0.5px solid #eeeeee;
  .itemOne {
    // .name {
    // }
    .jinbi {
      margin-top: 8px;
    }
  }
  .itemTwo {
    width: 18%;
    text-align: right;
  }
}

.noneList {
  margin-top: 100px;
  text-align: center;
  color: #bfbfbf;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 0px;
  font-size: 13px;
  //color: #999;
  color: #ff7b00;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #ff7b00;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.subItemBut /deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px;
}

.modalClass /deep/ .ant-modal-body {
  height: 560px;
  overflow: auto;
  padding: 24px 24px 0px 24px;
}
.colCalss {
  margin: 0px 20px;
}
.modalClass /deep/ .ant-modal-footer {
  padding: 14px 24px 24px 24px;
  //padding: 24px;
}
.typeOne {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.avatarClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 24px;

  .name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 0.5px solid #ff7b00;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 16px;

    // .headerText {
    // }

    .nameText {
      font-size: 14px;
      margin-left: 8px;
    }
  }
}
.mb {
  margin-bottom: 0px;
}
.nameMb {
  margin-bottom: 24px;
}
.subContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 20px;
  }

  span {
    font-size: 14px;
    color: #333333;
  }

  .sub-content {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sub-time {
    margin-left: auto;
    white-space: nowrap;
  }
}

.iconClass {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffc85b;
  color: #ffffff;
  text-align: center;
  padding-top: 4px;
}
.headerTextC {
  background: #ffc85b;
}
.nameC {
  border: 0.5px solid #ffc85b !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 16px;
  margin-bottom: 24px;
}
.mbOne {
  margin-bottom: 0px;
}

.ribbon {
  // width: 96px;
  margin-left: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .dropDown {
    position: relative;
    border-radius: 4px;
    background: #ffffff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .editImg {
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
    .editDelete {
      background: #fff;
      padding: 8px 16px;
      border-radius: 4px;
      position: absolute;
      top: 26px;
      left: -18px;
      cursor: pointer;
      width: 104px;
      color: #3d3d3d;
      z-index: 2;
      .edit {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .editImg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
      .delete {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 16px;
        .deleteImg {
          width: 16px;
          height: 16px;
          color: #ff8f1f;
          margin-right: 10px;
        }
      }
    }
  }

  .timeTime {
    border-radius: 4px;
    background: #ffffff;
    width: 24px;
    height: 24px;
    margin: 0px 8px;
    .deleteImg {
      //margin: 0px 12px;
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
  }

  .gouGou {
    border-radius: 4px;
    background: #ffffff;
    width: 24px;
    height: 24px;
    .gou {
      margin-left: 4px;
    }
  }
  .timeModal {
    position: absolute;
    top: 30px;
    left: -130px;
  }
}

.showLine {
  text-decoration: line-through;
  color: rgba(102, 102, 102, 0.9);
}
</style>
